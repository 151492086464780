<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left"></div>
      <div class="pageFucView_right">
        <el-form style="display: flex" label-width="80px" label-position="left">
          <el-form-item label="商品名称:" style="margin-top: 0">
            <el-input v-model="search.kind_id" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="用户名称:" style="margin-top: 0">
            <el-input v-model="search.user_id" placeholder="请输入用户名称"></el-input>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetHandle">重置</el-button>
        </el-form>
      </div>
    </div>

    <el-table
        :data="list"
        style="width: 100%"
        border
        >
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          label="商品"
      >
        <template #default="{ row }">
          <div class="goodsInfoView">
            <el-image :src="row.goods_obj.img" class="goodsPic" />
            <span class="goodsName">{{ row.goods_obj.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="用户">
        <template #default="{ row }">
          <div class="goodsInfoView">
            <el-image :src="row.user.pic" class="goodsPic" />
            <div style="width: calc(100% - 45px - 10px)">
              <span class="goodsName" style="width: 100%;-webkit-line-clamp: 1;">{{ row.user.name }}</span>
              <span>{{row.ip_address}}</span>
            </div>

          </div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="content"
          label="评价">
      </el-table-column>
      <el-table-column
          align="center"
          label="是否回复">
        <template #default="{ row }">
          <span>{{row.reply_content.length > 0?'已回复':'未回复'}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="是否显示"
          prop="sales"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.is_show == 0 ? '点击显示' : '点击不显示'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.is_show"
                :active-value="1"
                :inactive-value="0"
                :value="true"
                @change="hideHandle(row.id, row.is_show)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="创建">
        <template #default="{ row }">
          <span>{{row.create_at |formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template #default="{ row }">
          <el-button type="text" @click="reduction(row.id)">查看</el-button>
          <el-button type="text" @click="handleDelete(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="search['per-page']" :current-page.sync="search.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>
<script>
import {apiDeleteComment, apiGetCommentList, apiHideComment} from "@/request/api";

export default {
    props: [],
    data() {
      return {
        pageCount: 0,
        search: {},
        list: [],
      }
    },
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
  },
    created() {
      this.initSearch()
      this.getData()
    },
    methods: {
      initSearch() {
        this.search = {
          kind_id: '',
          user_id: '',
          page: 1,
          'per-page': 10,
        }
      },
      resetHandle() {
        this.initSearch()
        this.getData()
      },
      searchHandle() {
        this.search.page = 1;
        this.getData()
      },
      pageChangeHandle(){
        this.getData()
        window.screenTop = 0;
      },
      getData(){
        apiGetCommentList(this.search).then(res => {
          this.list = res.data.list
          this.pageCount = res.data.count
        })
      },
      reduction(id){
        this.$router.push({
          path: '/goods/goodsReview',
          query: {
            id: id,
          }
        })
      },
      hideHandle(id, is_show){
        apiHideComment({id:id, is_show: is_show}).then(res => {
          this.getData()
        })
      },
      handleDelete(id) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteComment({id:id}).then(res => {
            this.getData()
          })
        })
      }
    }
}

</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}
.goodsInfoView {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}
.goodsInfoView .goodsPic {
  width: 45px;height: 45px;border-radius: 10px;margin-right: 10px
}
.goodsInfoView .goodsName {
  max-height: 50px;
  width: calc(100% - 45px - 10px);
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
