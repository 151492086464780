var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"pageFucView"},[_c('div',{staticClass:"pageFucView_left"}),_c('div',{staticClass:"pageFucView_right"},[_c('el-form',{staticStyle:{"display":"flex"},attrs:{"label-width":"80px","label-position":"left"}},[_c('el-form-item',{staticStyle:{"margin-top":"0"},attrs:{"label":"商品名称:"}},[_c('el-input',{attrs:{"placeholder":"请输入商品名称"},model:{value:(_vm.search.kind_id),callback:function ($$v) {_vm.$set(_vm.search, "kind_id", $$v)},expression:"search.kind_id"}})],1),_c('el-form-item',{staticStyle:{"margin-top":"0"},attrs:{"label":"用户名称:"}},[_c('el-input',{attrs:{"placeholder":"请输入用户名称"},model:{value:(_vm.search.user_id),callback:function ($$v) {_vm.$set(_vm.search, "user_id", $$v)},expression:"search.user_id"}})],1),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.searchHandle}},[_vm._v("搜索")]),_c('el-button',{attrs:{"icon":"el-icon-refresh-right"},on:{"click":_vm.resetHandle}},[_vm._v("重置")])],1)],1)]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"id","label":"ID","width":"80"}}),_c('el-table-column',{attrs:{"label":"商品"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"goodsInfoView"},[_c('el-image',{staticClass:"goodsPic",attrs:{"src":row.goods_obj.img}}),_c('span',{staticClass:"goodsName"},[_vm._v(_vm._s(row.goods_obj.name))])],1)]}}])}),_c('el-table-column',{attrs:{"label":"用户"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"goodsInfoView"},[_c('el-image',{staticClass:"goodsPic",attrs:{"src":row.user.pic}}),_c('div',{staticStyle:{"width":"calc(100% - 45px - 10px)"}},[_c('span',{staticClass:"goodsName",staticStyle:{"width":"100%","-webkit-line-clamp":"1"}},[_vm._v(_vm._s(row.user.name))]),_c('span',[_vm._v(_vm._s(row.ip_address))])])],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"content","label":"评价"}}),_c('el-table-column',{attrs:{"align":"center","label":"是否回复"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.reply_content.length > 0?'已回复':'未回复'))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"是否显示","prop":"sales","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":row.is_show == 0 ? '点击显示' : '点击不显示',"enterable":false,"placement":"top"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0,"value":true},on:{"change":function($event){return _vm.hideHandle(row.id, row.is_show)}},model:{value:(row.is_show),callback:function ($$v) {_vm.$set(row, "is_show", $$v)},expression:"row.is_show"}})],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"创建"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(row.create_at)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.reduction(row.id)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete(row.id)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"pageView"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","page-size":_vm.search['per-page'],"current-page":_vm.search.page,"total":_vm.pageCount},on:{"update:currentPage":function($event){return _vm.$set(_vm.search, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.search, "page", $event)},"current-change":_vm.pageChangeHandle}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }